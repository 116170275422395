import React, {useState} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import TransportationChartData from "../content/golconda_il.json"

const configChart = ({ data }) => {
    var observedData = (data.observed).map(function(point){
        const dateString = point[0]
        const [datePart, timePart] = dateString.split(" ")
        const [year, month, day] = datePart.split("-").map(Number);
        const [hour, minute, second] = timePart.split(":").map(Number);

        return [new Date(Date.UTC(year, month - 1, day, hour, minute, second)).getTime(), parseFloat(point[1])]
    })
    var forcastedData = (data.forecasted).map(function(point){
        const dateString = point[0]
        const [datePart, timePart] = dateString.split(" ")
        const [year, month, day] = datePart.split("-").map(Number);
        const [hour, minute, second] = timePart.split(":").map(Number);

        return [new Date(Date.UTC(year, month - 1, day, hour, minute, second)).getTime(), parseFloat(point[1])]
    })

    observedData = observedData.filter((_, index) => index % 24 === 0)
    forcastedData = forcastedData.filter((_, index) => (index % 24 === 17 || index === 1))
    return {
        chart: {
            type: 'line',
            zoomType: 'x',
        },
        title: {
            text: `2024 Ohio River At Paducah, KY`,
        },
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats:{
                day: '%m-%d',
                week: '%m-%d',
                month: '%m-%d',
                year: '%m-%d'
            }
        },
        yAxis: {
            title: {
                text: 'Height (ft)'
            },
            min: 10,
            max: 40
        },
        tooltip: {
            shared: true,
            crosshairs: true,
            borderWidth: 0,
            valueSuffix: ' ft'
        },
        plotOptions: {
            series: {
                states: {
                    hover: {
                        enabled: false
                    }
                }
            }
        },
        series: [{
            name: 'Observed',
            data: observedData,
            fillColor: 'rgba(30, 156, 230, 0.3)',
            color: 'rgb(30, 156, 230)',

        },
        {
            name: "Forecasted",
            data: forcastedData,
            dashStyle: 'LongDash',
            lineWidth: 3,
            fillColor: 'rgba(30, 156, 230, 0.3)',
            color: 'rgb(30, 156, 230)',
            marker: {
                enabled: false
            },
        }],
        credits: false,
        legend: {
            symbolHeight: 0,
            symbolWidth: 0,
            symbolRadius: 0,
            squareSymbol: false,
            useHTML: true,
            labelFormatter: function() {
                if(this.name === 'Forecasted') {
                    return `<div style="display:flex; align-items: center;"><div style="width: 15px; height: 4px; margin-right: 8px; background-color: rgb(30, 156, 230);"></div>Forecasted</div>`
                }
                return `<div style="display:flex; align-items: center;"><div style="width: 11px; height: 11px; border-radius: 50%; margin-right: 8px; background-color: rgb(30, 156, 230);"></div>Observed</div>`
            }
        }
    }
}

export default function MaritimeChart() {
    const [chartData] = useState({
        data: TransportationChartData,
        fetched: false
    })

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={configChart(chartData)}
        />
    )
}
